.image-container {
  text-align: center;
  justify-content: center;
}

.thumbnail-image {
  max-width: 100%;
  object-fit: contain; // Maintain the aspect ratio
  cursor: pointer;
}

.modal-overlay {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); // Dark overlay background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.full-screen-image {
  max-width: 100%;
  max-height: 100%;
  // object-fit: contain; // Maintain the aspect ratio
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  z-index: 9999;
}

// create a style for the message "Please contribute prayer schedule for this month"
.message {
  font-size: small;
  text-align: center;
  margin: 20px;;
}