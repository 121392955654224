
.fade-content {
  border: 2px solid black; /* Adjust border as needed */
  padding-left: 5px;           /* Add padding around the content */
  border-radius: 5px;      /* Adjust the radius for roundness */
  // box-shadow: 0 0 5px blue; 
  // animation: fadeInAndOut 4s infinite; /* Adjust duration as needed */
}

@keyframes fadeInAndOut {
  // 0%, 100% { opacity: 1; } /* Fully visible at start and end */
  // 50% { opacity: 0; }    /* Fully transparent in the middle */
  0% { opacity: 1; }      /* Start fully visible */
  25% { opacity: 0.8; }
  50% { opacity: 0.1; }
  75% { opacity: 0.8; }
  100% { opacity: 1; }  
}
