// Modal.module.scss
.modal {
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it appears above other content
}

.modalContent {
  width: 80%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 350px;
}

.appLinks {
  text-decoration: none; // Remove underline
  color: #000; // Set link color

  // Change color on hover without underline
  &:hover {
    color: #555;
  }
}

.links {
  // color: #333;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  a {
    margin: 10px;
    padding: 10px;
    width: 150px;
    height: 150px;
  }
  font-family: 'Roboto', sans-serif;
  font-style: bold;
}

.appIcon {
  width: 70px;
  height: 70px;
}
