/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.default-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* align-items: center;
    justify-content: center;
    padding: 1rem; */
  }
  
  .name {
    font-size: 1.2rem;
  }
  
  .contact {
    font-size: 1em;
  }
  
  .address {
    font-size: 1rem;
  }
  