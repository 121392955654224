// myIQ.module.scss

.container {
  padding: 20px;
  background: #f0f0f0;
  font-size: large;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.search-term {
  font-weight: bold;
}

.download-button {
  margin: 10px;
  padding: 10px;
  background-color: #0070f3;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #005bb5;
  }
}

// h1,
// h2,
// h3 {
//   color: #333;
// }
