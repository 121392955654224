.validToken {
    color: green;
    margin: 5px;
   
}
.icon {
    padding: 5px;
    vertical-align: middle;
}
.iconLabel {
    padding: 5px;
    vertical-align: middle;

}

.invalidToken {
    color: red;
    margin: 5px;
}

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
}